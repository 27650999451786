import React, { useContext, useRef, useState } from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Button,
    Box,
    Image,
    Flex,
    Text,
} from '@chakra-ui/react'
import { useNavigate } from "react-router-dom"
import { UserContext } from '../Authentication/ProtectedRoute'
import { FaEye, FaTrash } from 'react-icons/fa'
import { API_URL } from "../keys";
import EmptyCandidates from "../Resources/Images/empty_candidates.svg";

const CandidatsTable = ({ candidats, reload }) => {
    const user = useContext(UserContext)
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const [toDeletion, setToDeletion] = useState(null)

    const getActionByRole = (candidatTeam, candidatId) => {
        const workingTeam = user && user.teams && user.teams.find(team => team._id === candidatTeam)
        console.log("working team", workingTeam)
        if (workingTeam && workingTeam.role === "administrator") {
            return (<>
                <IconButton
                    aria-label='Read'
                    icon={<FaEye />}
                    mr={1}
                    onClick={() => navigate(`/candidat/${candidatId}`)}
                />
                <IconButton
                    onClick={() => {
                        setToDeletion({ candidatId: candidatId, teamId: candidatTeam })
                        onOpen()
                    }}
                    colorScheme='red'
                    aria-label='Delete'
                    icon={<FaTrash />}
                />
            </>)

        } else if (workingTeam && workingTeam.role === "member") {
            return (<>
                <IconButton
                    aria-label='Read'
                    icon={<FaEye />}
                    mr={1}
                    onClick={() => navigate(`/candidat/${candidatId}`)}
                />
            </>)
        } else {
            return (<></>)
        }

    }

    const handleDeletion = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/teams/${toDeletion.teamId}/candidate/${toDeletion.candidatId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else
                reload()

        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return candidats.length === 0 ? <Flex justifyContent={"center"}>
        <Box p={8}>
            <Image src={EmptyCandidates} alt="No candidates" maxHeight={500} />
            <Text textAlign={"center"} fontSize={20} color={"gray.600"}>Vos candidats apparaîtrons ici !</Text>
        </Box>
    </Flex> : (
        <>
            <TableContainer width={"100%"}>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Nom</Th>
                            <Th>Prénom</Th>
                            <Th>Email</Th>
                            <Th>Équipe</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {candidats.map((candidat, index) => (
                            <Tr key={index}>
                                <Td>{candidat.candidate_lastname}</Td>
                                <Td>{candidat.candidate_firstname}</Td>
                                <Td>{candidat.candidate_email}</Td>
                                <Td>{candidat.team_name}</Td>
                                <Td>{getActionByRole(candidat.team_id, candidat.candidate_id)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {
                    setToDeletion(null)
                    onClose()
                }}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Suppression de questionnaire
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Êtes-vous sûr de vouloir supprimer le candidat <b>{toDeletion && toDeletion.candidatId}</b> de l'équipe <b>{toDeletion && toDeletion.teamId}</b>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Annuler
                            </Button>
                            <Button colorScheme='red' onClick={() => {
                                handleDeletion()
                                onClose()
                            }} ml={3}>
                                Supprimer
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default CandidatsTable