import React, { useEffect, useState } from 'react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Button,
    VStack,
    Input,
    Box,
    Flex,
    Heading,
    Text,
    useToast
} from '@chakra-ui/react'
import { MdSchool } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../keys";
backgroundImage
import backgroundImage from "../Resources/Images/login_illustration.jpg";


const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})
    const toast = useToast()
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const { value } = e.target;
        if (!checkIfEmailIsValid(value)) {
            setErrors({ ...errors, email: "Le format de l'adresse mail est incorrect." })
        } else {
            if (errors.hasOwnProperty("email")) {
                delete errors.email
                setErrors(errors)
            }
        }
        setEmail(value)
    }

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setPassword(value)
    }

    const checkIfEmailIsValid = (value) => {
        if (value === "")
            return true
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    }

    const handleSubmit = async () => {
        try {
            const response = await fetch(API_URL + '/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            const user = await response.json();

            if (response.ok) {
                console.log('Request sent successfully', user);
                if (user) {
                    if (!user.token) {
                        toast({
                            title: 'Erreur.',
                            description: "Vos identifiants sont incorrects ou ne correspondent à aucun compte.",
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                        })
                    } else {
                        localStorage.setItem("access-token", user.token);
                        console.log("set token and redirect")
                        navigate("/");
                    }
                } else {
                    toast({
                        title: 'Erreur.',
                        description: "Une erreur est survenue lors de votre requête.",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    })
                }
            } else {
                console.error('Request failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Flex>
            <Flex flex={5} direction={"column"}>
                <Flex p={4} >
                    <Flex alignItems="center" gap={4} mb={4}>
                        <MdSchool size={50} color='teal' />
                        <Heading color='gray.600'>APTITUDE</Heading>
                    </Flex >
                </Flex>
                <Flex flex={1} justifyContent={"center"} alignItems={"center"}>
                    <Box width={500}>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <FormControl isInvalid={errors && errors.email}>
                                <FormLabel>Adresse email</FormLabel>
                                <Input type='email' value={email} onChange={handleEmailChange} placeholder="Renseignez votre adresse email" />
                                {errors && errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
                            </FormControl >
                            <FormControl isInvalid={errors && errors.email}>
                                <FormLabel>Mot de passe</FormLabel>
                                <Input type='password' value={password} onChange={handlePasswordChange} placeholder="Renseignez votre mot de passe" />
                                {errors && errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
                            </FormControl >
                            <Button
                                isDisabled={!checkIfEmailIsValid(email) || password === "" || email === ""}
                                mt={4}
                                colorScheme='teal'
                                onClick={handleSubmit}
                                mb={0}
                            >
                                Connexion
                            </Button>
                        </VStack>
                        <Text fontSize='xs' mt={2}>Pas encore de compte recruteur ?</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box flex={2} height={"100vh"} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "bottom" }}>

            </Box>
        </Flex>
    )
}

export default LoginForm
