import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import { Box, Button, Container, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement, Select, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FaArrowLeft, FaSave } from 'react-icons/fa'
import CreateJobOfferWysiwygComponent from '../Components/CreateJobOfferWysiwygComponent'
import { UserContext } from '../Authentication/ProtectedRoute'

const JobOfferUpdateComponent = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [newTag, setNewTag] = useState("")
    const [jobOfferContent, setJobOfferContent] = useState({ content: "", name: "", tags: [], team: null })
    const user = useContext(UserContext);

    useEffect(() => {
        if (id !== "new") {
            // Get current job offer from API
        }

        // Get current user teams
        console.log("cur user", user)

    }, [])


    const handleEditorChange = (content, editor) => {
        handleJobOfferChange("content", content)
    }

    const handleJobOfferChange = (field, value) => {
        setJobOfferContent({ ...jobOfferContent, [field]: value })
    }

    const handleJobOfferCreation = () => {
        console.log("SAVE JOB OFFER", jobOfferContent)
    }



    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4} mb={2}>
                    <Button onClick={() => navigate("/job-offer")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux offres d'emplois</Button>
                </Flex>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={2} color={"gray.700"}>{id === "new" ? "Création" : "Modification"} d'une offre d'emploi</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Box boxShadow="base" p={8}>
                    <Flex mb={4} gap={2}>
                        <FormControl flex={1}>
                            <FormLabel>Nom</FormLabel>
                            <Input type='text' placeholder='Développeur javascript fullstack' value={jobOfferContent.name} onChange={(e) => handleJobOfferChange("name", e.target.value)} />
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Choix de l'équipe</FormLabel>
                            <Select placeholder='Sélectionner une équipe' onChange={(e) => handleJobOfferChange("team", e.target.value)}>
                                {user.teams && user.teams.map((element, index) => (
                                    <option value={element._id} key={index}>{element.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl flex={3}>
                            <FormLabel>Compétences associées</FormLabel>
                            <Flex alignItems="center" gap={4} w={"100%"}>
                                <InputGroup width={"md"}>
                                    <Input
                                        type={'text'}
                                        placeholder='Javascript, Excel, SCRUM, etc...'
                                        value={newTag}
                                        onChange={(e) => setNewTag(e.target.value)}
                                    />
                                    <InputRightElement width='4.5rem' pr={1}>
                                        <Button size='xs' onClick={() => {
                                            let { tags } = jobOfferContent;
                                            tags.push(newTag)
                                            handleJobOfferChange("tags", tags);
                                            setNewTag("");
                                        }}>
                                            Ajouter
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <Flex align="center" gap={1}>
                                    {jobOfferContent.tags.map((tag, index) => <Tag
                                        key={index}
                                        variant='solid'
                                        colorScheme='teal'
                                    >
                                        <TagLabel>{tag}</TagLabel>
                                        <TagCloseButton onClick={() => handleJobOfferChange("tags", [...jobOfferContent.tags.slice(0, index), ...jobOfferContent.tags.slice(index + 1)])} />
                                    </Tag>)}
                                </Flex>
                            </Flex>
                        </FormControl>

                    </Flex>
                    <Flex direction="column" gap={4}>
                        <FormControl>
                            <FormLabel>Contenu</FormLabel>
                            <CreateJobOfferWysiwygComponent handleEditorChange={handleEditorChange} />
                        </FormControl>
                    </Flex>
                    <Flex mt={2} justifyContent="flex-end">
                        <Button onClick={handleJobOfferCreation} size="sm" leftIcon={<FaSave />} colorScheme="teal">Sauvegarder l'offre d'emploi</Button>
                    </Flex>
                </Box>
            </Container>
        </>
    )
}

export default JobOfferUpdateComponent