import { useState } from "react";
import { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../keys";


export const UserContext = createContext({ firstname: undefined, lastname: undefined, roles: undefined, email: undefined });

const ProtectedRoute = ({ component: Component, adminOnly: adminOnly, ...restOfProps }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({ firstname: undefined, lastname: undefined, roles: undefined, email: undefined });


    useEffect(() => {
        const loadData = async () => {
            let accessToken = localStorage.getItem('access-token');
            const response = await fetch(
                API_URL + '/users/infos',
                {
                    method: 'GET',
                    headers: {
                        'x-access-token': accessToken
                    }
                }
            );

            if (!response.ok) {
                localStorage.removeItem("access-token");
                navigate("/login");
                return undefined
            }

            const loadedUser = await response.json();

            if (!loadedUser) {
                localStorage.removeItem("access-token");
                navigate("/login");
            } else if (adminOnly && loadedUser.roles !== "administrator") {
                navigate("/not_found")
            }
            else {
                setUser(loadedUser);
            }
        }

        loadData();
    }, []);

    return <UserContext.Provider value={user}>
        <Component {...restOfProps} />
    </UserContext.Provider>
}

export default ProtectedRoute;