import { Flex, Text } from '@chakra-ui/react'
import { FaFileCircleCheck } from "react-icons/fa6";
import React from 'react'

const EmptyDoneQuestionnaireCard = () => {
    return (
        <Flex as='b' color={"gray.600"} alignItems="flex-start" justifyContent={"space-between"} boxShadow={"base"} p={4} gap={4}>
            <FaFileCircleCheck size={48} color="teal" />
            <Text textAlign="left">Aucun questionnaire n'a été réalisé par ce candidat</Text>
        </Flex>
    )
}

export default EmptyDoneQuestionnaireCard