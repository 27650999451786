import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Layout/Navbar';
import { Badge, Box, Button, CircularProgress, CircularProgressLabel, Container, Flex, Grid, Heading, IconButton, Tag, Text } from '@chakra-ui/react';
import { FaArrowLeft, FaFileSignature, FaTrash } from 'react-icons/fa';
import { IoRefresh } from "react-icons/io5";
import { HiOutlinePlus } from "react-icons/hi2";
import CandidatAddQuestionnaire from '../Components/CandidatAddQuestionnaire';
import EmptyRunningQuestionnaireCard from '../Components/EmptyRunningQuestionnaireCard';
import EmptyDoneQuestionnaireCard from '../Components/EmptyDoneQuestionnaireCard';
import { API_URL } from "../keys";



const CandidatComponent = ({ }) => {
    const { candidat_id } = useParams();
    const [candidat, setCandidat] = useState(null)
    const [questionnaires, setQuestionnaires] = useState(null)
    const [isAddQuestionnaireVisible, setIsAddQuestionnaireVisible] = useState(false)
    const [candidateRunningQuestionnaire, setCandidateRunningQuestionnaire] = useState(null);
    const [candidateDoneQuestionnaireRouter, setCandidateDoneQuestionnaireRouter] = useState(null)
    const navigate = useNavigate()

    const loadUserQuestionnaires = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(API_URL + '/questionnaires/getuserquestionnaires', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setQuestionnaires(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const fetchCandidat = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/users/candidat/${candidat_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setCandidat(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    };

    const addRunningQuestionnaire = async (questionnaire_id) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_running_questionnaire/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
                body: JSON.stringify({
                    questionnaire_id: questionnaire_id,
                    candidate_id: candidat._id
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            fetchCandidateRunningQuestionnaire();
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    const fetchCandidateRunningQuestionnaire = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_running_questionnaire/` + candidat._id, {
                method: 'GET',
                headers: {
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setCandidateRunningQuestionnaire(data)
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    const fetchCandidateDoneQuestionnaire = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_done_questionnaire/` + candidat._id, {
                method: 'GET',
                headers: {
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setCandidateDoneQuestionnaireRouter(data)
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    const deleteCandidateRunningQuestionnaire = async (questionnaire_id) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_running_questionnaire/` + questionnaire_id, {
                method: 'DELETE',
                headers: {
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            fetchCandidateRunningQuestionnaire();

        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }


    useEffect(() => {
        loadUserQuestionnaires();
        fetchCandidat();
    }, [])

    useEffect(() => {
        if (candidat) {
            fetchCandidateRunningQuestionnaire()
            fetchCandidateDoneQuestionnaire()
        }

    }, [candidat, questionnaires])

    if (!candidat) return null

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4} mb={2}>
                    <Button onClick={() => navigate("/candidats")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux candidats</Button>
                </Flex>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={2} color={"gray.700"}>Détail du candidat</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Box w='100%'>
                    <Heading mb={2} size={"lg"} color={"gray.700"}>Informations</Heading>

                    <Flex alignItems="baseline" gap={2}>
                        <Text fontSize='xl'>{candidat.firstname} {candidat.lastname}</Text>
                        <Tag fontSize='xs' type='whiteAlpha'>{candidat._id}</Tag>
                    </Flex>
                    <Flex alignItems="baseline" gap={2}>
                        <Text fontSize='sm'>{candidat.email}</Text>
                    </Flex>
                </Box>
                <Box w='100%' my={8}>
                    <Heading mb={2} size={"lg"} color={"gray.700"}>Questionnaires en cours</Heading>
                    <Grid gridTemplateColumns="repeat(6, 1fr)" gap={2}>
                        <Box border={"dashed"} borderColor={"teal.400"} background={"green.50"} minHeight={350} cursor="pointer" onClick={() => setIsAddQuestionnaireVisible(true)}>
                            <Flex height={"100%"} justifyContent={"center"} alignItems={"center"}>
                                <Flex direction="column" justifyContent="center" alignItems="center" mx={8}>
                                    <HiOutlinePlus size={90} color='teal' display={"block"} />
                                    <Text as="b" fontSize="xl" color='teal' textAlign="center">Proposer un nouveau questionnaire</Text>
                                </Flex>
                            </Flex>
                        </Box>
                        {candidateRunningQuestionnaire && candidateRunningQuestionnaire.length == 0 ? <EmptyRunningQuestionnaireCard /> : null}
                        {candidateRunningQuestionnaire && candidateRunningQuestionnaire.map((infos, index) => {
                            let questionnaire = infos.questionnaire_id
                            return <Box boxShadow={"base"} p={4} key={index + "-runnis-questionnaire"}>

                                <Flex justifyContent={"space-between"}>
                                    <Text as="b" fontSize='xl'>{questionnaire.name}</Text>
                                    <IconButton size={"sm"} colorScheme='red' icon={<FaTrash />} onClick={() => deleteCandidateRunningQuestionnaire(questionnaire._id)} />
                                </Flex>
                                <Text mt={4} fontSize='md'>{questionnaire.description}</Text>
                                <Box mt={2}>
                                    {questionnaire.tags.map((tag, index) => (
                                        <Badge key={"tag-" + index} mr={1}>{tag}</Badge>
                                    ))}
                                </Box>
                                <Box textAlign={"right"} mt={4}>
                                    <Text as="i"><b>{questionnaire.questions.length}</b> questions</Text>
                                </Box>
                            </Box>
                        })}
                    </Grid>
                </Box>
                <Box w='100%' my={8}>
                    <Heading mb={2} size={"lg"} color={"gray.700"}>Questionnaires réalisés</Heading>
                    <Grid gridTemplateColumns="repeat(4, 1fr)" gap={2}>
                        {candidateDoneQuestionnaireRouter && candidateDoneQuestionnaireRouter.length == 0 ? <EmptyDoneQuestionnaireCard /> : null}
                        {candidateDoneQuestionnaireRouter && candidateDoneQuestionnaireRouter.map((questionnaire, index) => {
                            return <Flex key={index + "-questionnaire"} boxShadow={"base"} p={4} direction="column">
                                <Flex alignItems="center" justifyContent={"space-between"}>
                                    <Flex direction={"column"}>
                                        <Text as="b" fontSize='xl'>{questionnaire.name}</Text>
                                        <Text fontSize='md'>{questionnaire.description}</Text>
                                    </Flex>
                                    <Box>
                                        <CircularProgress value={(questionnaire.good / questionnaire.questions.length) * 100} color='green.400'>
                                            <CircularProgressLabel>{(questionnaire.good / questionnaire.questions.length) * 100}%</CircularProgressLabel>
                                        </CircularProgress>
                                    </Box>
                                </Flex>
                                <Flex flex={1} justifyContent={"flex-end"} mt={4} gap={1}>
                                    <Button onClick={() => addRunningQuestionnaire(questionnaire.questionnaire_id)} colorScheme='teal' variant="ghost" leftIcon={<IoRefresh />}>Proposer un nouvel essai</Button>
                                    <Button onClick={() => navigate("/exam/responses/" + questionnaire._id)} colorScheme='teal' leftIcon={<FaFileSignature />}>Voir les résultats</Button>
                                </Flex>
                            </Flex>
                        })}
                    </Grid>
                </Box>
                <CandidatAddQuestionnaire isVisible={isAddQuestionnaireVisible} questionnaires={questionnaires} cancel={() => setIsAddQuestionnaireVisible(false)} addRunningQuestionnaire={addRunningQuestionnaire} reload={fetchCandidateRunningQuestionnaire} />
            </Container>
        </>
    )
}

export default CandidatComponent