import { Box, Checkbox, Code, Flex, Tag, Text } from '@chakra-ui/react'
import React from 'react'

const ExamQuestionCard = ({ description, responses, type, responsesChecked, handleChange, questionIndex }) => {
    const GetLabelFromType = () => {
        if (type === "unique") {
            return "Choix unique"
        } else if (type === "multiple") {
            return "Choix multiple"
        } else {
            return "Aucun"
        }
    }

    return (
        <Box w='100%' boxShadow='base' p={8} color="gray.700">
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{description}</Text>
                {/* <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag> */}
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType()}</Tag>
            </Flex>
            {responses.map((res, index) => <Flex gap={4}>
                <Checkbox checked={responsesChecked[index]} onChange={(e) => handleChange(questionIndex, index, e.target.checked)} />
                <Text key={index} fontSize='md'><Code>{res}</Code></Text>
            </Flex>)}
        </Box>
    )
}

export default ExamQuestionCard