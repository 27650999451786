import React, { useContext } from 'react'
import { Flex, Box, Heading, Spacer, Button, IconButton } from '@chakra-ui/react';
import { MdSchool } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { UserContext } from "../Authentication/ProtectedRoute";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
    const user = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="1rem"
            color="gray.800"
            boxShadow={"base"}
        >
            <Flex alignItems="center" gap={2} mr={50} onClick={() => navigate("/")} cursor="pointer">
                <MdSchool size={40} color='teal' />
                <Heading size={20} color='gray.600'>APTITUDE</Heading>
            </Flex >

            <Box
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                flexGrow={1}
            >
                {/* <Button variant="ghost" mr={2}>
                    Offres d'emplois
                </Button> */}
                {user && user.roles !== "candidate" ? <><Button variant={location.pathname === "/questionnaires" ? "solid" : "ghost"} colorScheme={location.pathname === "/questionnaires" ? "teal" : "gray"} mr={2} onClick={() => navigate("/questionnaires")}>
                    Questionnaires
                </Button>
                    <Button mr={2} variant={location.pathname === "/candidats" ? "solid" : "ghost"} colorScheme={location.pathname === "/candidats" ? "teal" : "gray"} onClick={() => navigate("/candidats")}>
                        Candidats
                    </Button></> : null}
                {/* <Button variant="ghost" mr={2}>
                    Statistiques
                </Button> */}
            </Box>

            <Spacer />

            <Box
                display={{ base: 'none', md: 'flex' }}
                width={{ base: 'full', md: 'auto' }}
                alignItems="center"
                gap={2}
            >
                <Button
                    leftIcon={<FaUser />}
                    colorScheme="teal"
                    // size="lg"
                    onClick={() => navigate("/profil")}
                >
                    {user.firstname} {user.lastname}
                </Button>
            </Box>
        </Flex>
    );
}

export default Navbar