import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Divider, Text, useToast } from '@chakra-ui/react'
import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import CopyToClipboard from '../Utils/CopyToClipboard'

const CandidatsCreation = ({ isVisible, teams, cancel }) => {
  const toast = useToast()


  if (!isVisible) return null
  return (
    <AlertDialog
      isOpen={isVisible}
      onClose={() => cancel()}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontWeight='bold'>
            Ajouter un questionnaire
          </AlertDialogHeader>

          <AlertDialogBody>
            {teams.map((team, index) => {
              return (<React.Fragment key={index}>
                <Box  my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Text fontSize='sm'>
                    {team.name}
                  </Text>
                  <Button rightIcon={<FaEnvelope />} size={"sm"} variant={"ghost"} color={"teal"} onClick={() => {
                    const protocol = window.location.protocol + "//"; // e.g., 'http:' or 'https:'
                    const host = window.location.host;
                    let status = CopyToClipboard(protocol + host + "/register_candidate/" + team._id)

                    if (status) {
                      toast({
                        description: "Votre texte a bien été copié !",
                        status: 'info',
                        duration: 9000,
                        isClosable: true,
                      })
                    }
                  }}>Copier le lien</Button>
                </Box>
                <Divider />
              </React.Fragment>)
            })}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => cancel()} ml={3}>
              Fermer
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default CandidatsCreation