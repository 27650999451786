import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import { Container, Box, Heading, Flex, Button, Input } from '@chakra-ui/react';
import CandidatsTable from '../Components/CandidatsTable';
import CandidatsCreation from '../Components/CandidatsCreation';
import { FaPlusSquare } from 'react-icons/fa';
import { UserContext } from '../Authentication/ProtectedRoute';
import { API_URL } from "../keys";

const CandidatsComponent = () => {
    const [candidats, setCandidats] = useState([])
    const [isCandidatCreationVisible, setIsCandidatCreationVisible] = useState(false)
    const user = useContext(UserContext);
    const [searchBar, setSearchBar] = useState("")

    useEffect(() => {
        loadUserCandidats()
    }, [])

    useEffect(() => {
        console.log("context", user)
    }, [user])

    const loadUserCandidats = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(API_URL + '/teams/getteamsbyuser', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            const transformedTeams = data.map(team => {
                return team.candidate.map(candidate => {
                    return {
                        team_name: team.name,
                        team_id: team._id,
                        candidate_firstname: candidate.firstname,
                        candidate_lastname: candidate.lastname,
                        candidate_email: candidate.email,
                        candidate_id: candidate._id
                    };
                });
            }).flat();


            setCandidats(transformedTeams)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>

                    <Heading mb={2} color={"gray.700"}>Les Candidats</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                    <Button leftIcon={<FaPlusSquare />} onClick={() => setIsCandidatCreationVisible(true)} size="sm" colorScheme="teal" variant="ghost">
                        Inviter un candidat
                    </Button>
                </Flex>
                <Box my={2}>
                    <Input placeholder='Rechercher un candidat' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                </Box>
                <Box w='100%' boxShadow='base'>
                    <CandidatsTable candidats={candidats.filter(candidat =>
                        candidat.candidate_firstname.toLowerCase().includes(searchBar.toLowerCase()) ||
                        candidat.candidate_lastname.toLowerCase().includes(searchBar.toLowerCase()) ||
                        candidat.candidate_email.toLowerCase().includes(searchBar.toLowerCase()))}
                        reload={loadUserCandidats} />
                    <CandidatsCreation isVisible={isCandidatCreationVisible} teams={user.teams} cancel={() => setIsCandidatCreationVisible(false)} />
                </Box>
            </Container>
        </>
    )
}

export default CandidatsComponent