import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const CreateJobOfferWysiwygComponent = ({ handleEditorChange }) => {
  return (
    <Editor
      apiKey="ncbe553vnl3y8worxq9kvsmdg3tqw5t0vt28s6xqwqtvhn8j"
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'lists'
        ],
        toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default CreateJobOfferWysiwygComponent;