import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Divider, Text, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { FaEnvelope, FaPlus, FaPlusSquare } from 'react-icons/fa'
import CopyToClipboard from '../Utils/CopyToClipboard'

const CandidatAddQuestionnaire = ({ isVisible, questionnaires, cancel, addRunningQuestionnaire, reload }) => {
    if (!isVisible) return null
    return (
        <AlertDialog
            isOpen={isVisible}
            onClose={() => cancel()}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontWeight='bold'>
                        Ajouter au questionnaire
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {questionnaires.map((questionnaire, index) => {
                            return <React.Fragment key={index}>
                                <Box my='2' display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Text fontSize='sm'>{questionnaire.questionnaire.name}</Text>
                                    <Button rightIcon={<FaPlus />} size={"sm"} variant={"ghost"} color={"teal"} onClick={async () => {
                                        await addRunningQuestionnaire(questionnaire.questionnaire._id);
                                        await reload();
                                    }}>Ajouter le questionnaire</Button>
                                </Box>
                                <Divider />
                            </ React.Fragment>
                        })}

                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={() => cancel()} ml={3}>
                            Fermer
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default CandidatAddQuestionnaire