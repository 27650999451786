import React, { useEffect } from 'react'
import LoginForm from '../Forms/LoginForm'
import { Flex } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";


const LoginComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('access-token')) {
      navigate("/");
    }
  }, [])

  return (
    <>
      <LoginForm />
    </>
  )
}

export default LoginComponent