import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import QuestionnairesTable from '../Components/QuestionnairesTable'
import { Container, Box, Heading, Flex, Button, Input } from '@chakra-ui/react';
import { FaPlusSquare } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../keys";


const QuestionnairesComponent = () => {
    const [questionnaires, setQuestionnaires] = useState([])
    const [searchBar, setSearchBar] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        loadUserQuestionnaires()
    }, [])

    const loadUserQuestionnaires = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(API_URL + '/questionnaires/getuserquestionnaires', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setQuestionnaires(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4} mb={2}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>

                    <Heading mb={2} color={"gray.700"}>Les Questionnaires</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                    <Button leftIcon={<FaPlusSquare />} onClick={() => navigate("/questionnaire/update/new")} size="sm" colorScheme="teal" variant="ghost">
                        Ajouter un questionnaire
                    </Button>
                </Flex>
                <Box my={2}>
                    <Input placeholder='Rechercher un questionnaire' value={searchBar} onChange={e => setSearchBar(e.target.value)} />
                </Box>
                <Box w='100%' boxShadow='base'>
                    <QuestionnairesTable questionnaires={questionnaires.filter(row => row.questionnaire.name.toLowerCase().includes(searchBar.toLowerCase()) || row.questionnaire.description.toLowerCase().includes(searchBar.toLowerCase()))} reload={loadUserQuestionnaires} />
                </Box>
            </Container>
        </>
    )
}

export default QuestionnairesComponent