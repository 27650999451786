import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import { Box, Button, ButtonGroup, Checkbox, Container, Editable, EditableInput, EditablePreview, Flex, FormControl, FormLabel, Heading, IconButton, Input, InputGroup, InputRightElement, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Radio, RadioGroup, Select, Stack, Tag, TagCloseButton, TagLabel, Textarea, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { FaArrowLeft, FaPlus, FaSave, FaTrash } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from "../keys";

const QuestionnaireUpdateComponent = ({ }) => {

    const { id } = useParams();
    const [initialValues, setInitialValues] = useState(null);
    const [newTag, setNewTag] = useState("")
    const [newAccessTeam, setNewAccessTeam] = useState("")
    const [newModifyTeam, setNewModifyTeam] = useState("")
    const [newAnswer, setNewAnswer] = useState({})
    const [newQuestion, setNewQuestion] = useState("")
    const [teams, setTeams] = useState(null)

    const toast = useToast()
    const navigate = useNavigate();

    const fetchQuestionnaire = async (questionnaireId) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/questionnaires/getuserquestionnaire/${questionnaireId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setInitialValues(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    };

    const fetchUserTeam = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(API_URL + '/teams/getteamsbyuser', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            setTeams(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        fetchQuestionnaire(id);
        fetchUserTeam()
    }, [])

    const handleQuestionnaireChanges = (field, value) => {
        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, [field]: value } })
    }

    const handleQuestionChanges = (index, field, value) => {
        let { questions } = initialValues.questionnaire;
        questions[index][field] = value;

        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions } })
    }

    const handleQuestionResponseChanges = (questionIndex, responseIndex, value) => {
        let { questions } = initialValues.questionnaire;
        questions[questionIndex]["responses"][responseIndex] = value;

        setInitialValues({ ...initialValues, questionnaire: { ...initialValues.questionnaire, questions: questions } })
    }

    const handleQuestionAnswersChange = (question_id, answer_index, isChecked) => {
        let acceptedAnswer = initialValues.questionsAnswers[question_id];
        if (!acceptedAnswer) {
            acceptedAnswer = []
        }
        if (isChecked) {
            acceptedAnswer.push(answer_index)
        } else {
            acceptedAnswer = acceptedAnswer.filter(el => el != answer_index)
        }


        setInitialValues({ ...initialValues, questionsAnswers: { ...initialValues.questionsAnswers, [question_id]: acceptedAnswer } })
    }

    const handleSaveQuestionnaire = async () => {
        let accessToken = localStorage.getItem('access-token');
        const parts = window.location.href.split('/');
        const lastPart = parts[parts.length - 1];

        if (lastPart === "new")
            initialValues._id = "new"

        if (lastPart === "new" && initialValues.questionnaire.teamOwner === null)
            initialValues.questionnaire.teamOwner = teams[0]._id

        if (lastPart === "new" && initialValues.questionnaire.type === "")
            initialValues.questionnaire.type = 'public'

        const response = await fetch(API_URL + '/questionnaires/updatebyid', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': accessToken

            },
            body: JSON.stringify(initialValues)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            // Notification
            toast({
                title: 'Modification du questionnaire',
                description: "Le server a bien pris en compte les modifications.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        }).catch(error => {
            console.error('Error updating questionnaire:', error);
            toast({
                title: 'Modification du questionnaire',
                description: "Le serveur n'a pas réussi à mettre à jour le questionnaire",
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: "top"
            })
        });
    }

    const generateUuid = () => {
        const uuid = uuidv4().replace(/-/g, ''); // Remove dashes
        return uuid.slice(0, 24); // Trim to 24 characters
    }

    if (!initialValues)
        return "waiting..."

    return (
        <>
            <Navbar />
            <Container maxW="full" p={4}>
                <Flex alignItems='center' px={8} gap={4} mb={2}>
                    <Button onClick={() => navigate("/questionnaires")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux questionnaires</Button>
                </Flex>
                <Box w='100%' p={8} pt={0}>
                    <Flex

                        position="sticky"
                        top={0}
                        left={0}
                        bg="white"
                        p={4}
                        borderBottom='2px'
                        borderColor='teal'
                        zIndex="10"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Heading>{id === "new" ? "Création " : "Modification"} de questionnaire</Heading>
                        <Flex>
                            <Button leftIcon={<FaSave />} onClick={handleSaveQuestionnaire}>Enregistrer le questionnaire</Button>
                        </Flex>
                    </Flex>
                    <Flex w="full" gap={2} mt={4} >
                        <Flex flex={1} direction="column" gap={6} boxShadow="base" p={8}>
                            <Heading>Informations générales</Heading>
                            <FormControl>
                                <FormLabel>Nom</FormLabel>
                                <Input type='text' value={initialValues.questionnaire.name} onChange={(e) => handleQuestionnaireChanges('name', e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea height={215} value={initialValues.questionnaire.description} onChange={(e) => handleQuestionnaireChanges('description', e.target.value)} />
                            </FormControl>
                        </Flex>
                        <Flex flex={1} direction="column" gap={2}>
                            <Flex flex={1} direction="column" gap={6} boxShadow="base" p={8}>
                                <Heading>Configuration</Heading>
                                <Flex gap={5}>
                                    <FormControl flex={1} >
                                        <FormLabel>Visibilité</FormLabel>

                                        <RadioGroup value={initialValues.questionnaire.type || 'public'} onChange={(e) => handleQuestionnaireChanges('type', e)} >
                                            <Stack spacing={5} direction='row'>
                                                <Radio colorScheme='green' value='public'>
                                                    Publique
                                                </Radio>
                                                <Radio colorScheme='green' value='private'>
                                                    Privée
                                                </Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormControl flex={1}>
                                        <FormLabel>Équipe propriétaire</FormLabel>
                                        <Select disabled={id !== "new"} spacing={5} value={initialValues.questionnaire.teamOwner || ""} onChange={(e) => handleQuestionnaireChanges('teamOwner', e.target.value)}>
                                            {teams && teams.length > 0 && teams.map((team, index) => <option key={"team" + index} value={team._id}>{team.name}</option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl flex={2}>
                                        <FormLabel>Compétences associées</FormLabel>
                                        <InputGroup mb={2}>
                                            <Input
                                                type={'text'}
                                                placeholder='Javascript, Excel, SCRUM, etc...'
                                                value={newTag}
                                                onChange={(e) => setNewTag(e.target.value)}
                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={() => {
                                                    let { tags } = initialValues.questionnaire;
                                                    tags.push(newTag)
                                                    handleQuestionnaireChanges("tags", tags);
                                                    setNewTag("");
                                                }}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.tags.map((tag, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{tag}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("tags", [...initialValues.questionnaire.tags.slice(0, index), ...initialValues.questionnaire.tags.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                </Flex>
                            </Flex>
                            <Flex flex={1} direction="column" gap={6} boxShadow="base" p={8}>
                                <Heading>Partage</Heading>
                                <Flex gap={5} justifyContent="flex-start">
                                    <FormControl>
                                        <FormLabel>Équipe en lecture</FormLabel>
                                        <InputGroup width={"md"} mb={2}>
                                            <Input
                                                type={'text'}
                                                value={newAccessTeam}
                                                onChange={(e) => setNewAccessTeam(e.target.value)}
                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={() => {
                                                    let { accessTeam } = initialValues.questionnaire;
                                                    accessTeam.push(newAccessTeam)
                                                    handleQuestionnaireChanges("accessTeam", accessTeam);
                                                    setNewAccessTeam("");
                                                }}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.accessTeam.map((team, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{team}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("accessTeam", [...initialValues.questionnaire.accessTeam.slice(0, index), ...initialValues.questionnaire.accessTeam.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Équipe en écriture</FormLabel>
                                        <InputGroup width={"md"} mb={2}>
                                            <Input
                                                type={'text'}
                                                value={newModifyTeam}
                                                onChange={(e) => setNewModifyTeam(e.target.value)}
                                            />
                                            <InputRightElement width='4.5rem' pr={1}>
                                                <Button size='xs' onClick={() => {
                                                    let { modifyTeam } = initialValues.questionnaire;
                                                    modifyTeam.push(newAccessTeam)
                                                    handleQuestionnaireChanges("modifyTeam", modifyTeam);
                                                    setNewModifyTeam("");
                                                }}>
                                                    Ajouter
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                        <Flex align="center" gap={1}>
                                            {initialValues.questionnaire.modifyTeam.map((team, index) => <Tag
                                                key={index}
                                                variant='solid'
                                                colorScheme='teal'
                                            >
                                                <TagLabel>{team}</TagLabel>
                                                <TagCloseButton onClick={() => handleQuestionnaireChanges("modifyTeam", [...initialValues.questionnaire.modifyTeam.slice(0, index), ...initialValues.questionnaire.modifyTeam.slice(index + 1)])} />
                                            </Tag>)}
                                        </Flex>
                                    </FormControl>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
                <Box w='100%' p={8}>
                    <Heading>Questions</Heading>
                    <Flex w="full" gap={2} mt={4} direction="column">
                        {initialValues.questionnaire.questions.map((question, index) => (
                            <Box boxShadow="base" p={8} key={index}>
                                <FormControl mb={4}>
                                    <FormLabel>Titre</FormLabel>
                                    <Flex alignItems="center" gap={2}>
                                        <Input type='text' value={question.description} onChange={(e) => handleQuestionChanges(index, "description", e.target.value)} />
                                        <Button colorScheme='red' variant="ghost" aria-label={"Delete question " + index} leftIcon={<FaTrash />} onClick={() => {
                                            handleQuestionnaireChanges("questions", [...initialValues.questionnaire.questions.slice(0, index), ...initialValues.questionnaire.questions.slice(index + 1)])
                                        }}>Supprimer la question</Button>
                                    </Flex>
                                </FormControl>


                                <FormControl mb={4}>
                                    <FormLabel>Type de question</FormLabel>
                                    <Flex alignItems="center" gap={2}>
                                        <RadioGroup value={question.type} onChange={(newType) => {
                                            // If type is unique make sure only 1 answer is selected
                                            handleQuestionChanges(index, "type", newType)

                                            if (newType === "unique") {
                                                let sortedResponse = initialValues.questionsAnswers[question._id]
                                                sortedResponse.sort((a, b) => a - b)
                                                setInitialValues({ ...initialValues, questionsAnswers: { ...initialValues.questionsAnswers, [question._id]: [sortedResponse[0]] } })
                                            }
                                        }}>
                                            <Stack spacing={4} direction='row'>
                                                <Radio value='multiple'>
                                                    Choix multiple
                                                </Radio>
                                                <Radio value='unique'>Choix unique</Radio>
                                            </Stack>
                                        </RadioGroup>
                                    </Flex>
                                    {/* <Flex alignItems="center" gap={2}>
                                        <Input type='text' value={question.description} onChange={(e) => handleQuestionChanges(index, "description", e.target.value)} />
                                        <Button colorScheme='red' variant="ghost" aria-label={"Delete question " + index} leftIcon={<FaTrash />} onClick={() => {
                                            handleQuestionnaireChanges("questions", [...initialValues.questionnaire.questions.slice(0, index), ...initialValues.questionnaire.questions.slice(index + 1)])
                                        }}>Supprimer la question</Button>
                                    </Flex> */}
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Réponses</FormLabel>
                                    {question.responses.map((response, response_index) => (
                                        <Flex mb={1} gap={2} key={response_index}>
                                            <Checkbox isChecked={initialValues.questionsAnswers[question._id] && initialValues.questionsAnswers[question._id].includes(response_index)} onChange={(e) => handleQuestionAnswersChange(question._id, response_index, e.target.checked)} />
                                            <Input type='text' value={response} onChange={(e) => handleQuestionResponseChanges(index, response_index, e.target.value)} />
                                            <IconButton variant="ghost" colorScheme='red' aria-label={"Delete response " + response_index} icon={<FaTrash />} onClick={() => {

                                                handleQuestionChanges(index, "responses", [...question.responses.slice(0, response_index), ...question.responses.slice(response_index + 1)])
                                            }} />
                                        </Flex>
                                    ))}
                                    <Flex mb={1} gap={2}>
                                        <Input ml={6} type='text' value={newAnswer[question._id] || ""} onChange={(e) => {
                                            if (!newAnswer[question._id]) {
                                                newAnswer[question._id] = ""
                                            }
                                            setNewAnswer({ ...newAnswer, [question._id]: e.target.value })
                                        }} />
                                        <IconButton aria-label={"Add response " + index} icon={<FaPlus />} onClick={() => {
                                            setNewAnswer({ ...newAnswer, [question._id]: "" })

                                            handleQuestionChanges(index, "responses", [...question.responses, newAnswer[question._id]])
                                        }} />
                                    </Flex>
                                </FormControl>

                            </Box>
                        ))}
                        <Flex mb={1} gap={2}>
                            <Input type='text' value={newQuestion} placeholder='Titre de la nouvelle question' onChange={(e) => {
                                setNewQuestion(e.target.value)
                            }} />

                            <Button aria-label={"Add question "} leftIcon={<FaPlus />} onClick={() => {
                                handleQuestionnaireChanges("responses", initialValues.questionnaire.questions.push({ _id: generateUuid(), description: newQuestion, type: "unique", responses: [] }))
                                setNewQuestion("")
                            }}>
                                Ajouter une question
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            </Container >
        </>
    )
}

export default QuestionnaireUpdateComponent