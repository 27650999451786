import { Box, Checkbox, Code, Flex, Tag, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'

const ExamResultQuestionCard = ({ description, responses, type, questionIndex, candidateAnswers, questionnaireAnswers }) => {
    const GetLabelFromType = () => {
        if (type === "unique") {
            return "Choix unique"
        } else if (type === "multiple") {
            return "Choix multiple"
        } else {
            return "Aucun"
        }
    }

    const checkColorDependingToResponse = (acceptedAnswers, userAnswer, answerIndex) => {
        if (acceptedAnswers.includes(answerIndex)) return "green"
        else if (acceptedAnswers.includes(answerIndex) && userAnswer.includes(answerIndex.toString())) return "green"
        else if (!acceptedAnswers.includes(userAnswer) && userAnswer.includes(answerIndex.toString())) return "red"
        else return "gray"
    }

    const checkLabelDependingToResponse = (acceptedAnswers, userAnswer, answerIndex) => {
        if (acceptedAnswers.includes(answerIndex) && userAnswer.includes(answerIndex.toString())) return " - Le candidat a coché cette bonne réponse"
        else if (acceptedAnswers.includes(answerIndex)) return " - Le candidat n'a pas coché cette bonne réponse"
        else if (!acceptedAnswers.includes(userAnswer) && userAnswer.includes(answerIndex.toString())) return " - Le candidat a coché cette mauvaise réponse"
        else return null
    }

    if (!questionnaireAnswers) return null

    return (
        <Box w='100%' boxShadow='base' p={8} color="gray.700" key={"question" + questionIndex}>
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{description}</Text>
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType()}</Tag>
            </Flex>
            {responses.map((res, index) => {
                return <Flex gap={4} key={"response" + index}>
                    <Text key={index} fontSize='md'>{String.fromCharCode(65 + index)} - <Code colorScheme={checkColorDependingToResponse(questionnaireAnswers && questionnaireAnswers.acceptedAnswer, candidateAnswers, index)}>{res}</Code><i>{checkLabelDependingToResponse(questionnaireAnswers && questionnaireAnswers.acceptedAnswer, candidateAnswers, index)}</i></Text>
                </Flex>
            })}
        </Box >
    )
}

export default ExamResultQuestionCard