import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import Homepage from './Homepage';
import ProtectedRoute from './Authentication/ProtectedRoute';
import NotFoundComponent from './Routes/NotFoundComponent';
import LoginComponent from './Routes/LoginComponent';
import QuestionnairesComponent from './Routes/QuestionnairesComponent';
import QuestionnaireComponent from './Routes/QuestionnaireComponent';
import QuestionnaireUpdateComponent from './Routes/QuestionnaireUpdateComponent';
import ProfileComponent from './Routes/ProfileComponent';
import JobOfferUpdateComponent from './Routes/JobOfferUpdateComponent';
import CandidatsComponent from './Routes/CandidatsComponent';
import CandidatComponent from './Routes/CandidatComponent';
import CandidatRegistrationComponent from './Routes/CandidatRegistrationComponent';
import CandidatQuestionnaireExam from './Routes/CandidatQuestionnaireExam';
import CandidatHomepage from './Routes/CandidatHomepage';
import QuestionnaireResultsComponent from './Routes/QuestionnaireResultsComponent';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginComponent />
  },
  {
    path: "/register_candidate/:team_id",
    element: <CandidatRegistrationComponent />
  },
  {
    path: "/job-offer/update/:id",
    element: <ProtectedRoute component={JobOfferUpdateComponent} />
  },
  {
    path: "/exam",
    element: <ProtectedRoute component={CandidatHomepage} />
  },
  {
    path: "/exam/:questionnaire_id",
    element: <ProtectedRoute component={CandidatQuestionnaireExam} />
  },
  {
    path: "/exam/responses/:questionnaire_id",
    element: <ProtectedRoute component={QuestionnaireResultsComponent} />
  },
  {
    path: "/questionnaires",
    element: <ProtectedRoute component={QuestionnairesComponent} />
  },
  {
    path: "/questionnaire/:id",
    element: <ProtectedRoute component={QuestionnaireComponent} />
  },
  {
    path: "/questionnaire/update/:id",
    element: <ProtectedRoute component={QuestionnaireUpdateComponent} />
  },
  {
    path: "/candidats",
    element: <ProtectedRoute component={CandidatsComponent} />
  },
  {
    path: "/candidat/:candidat_id",
    element: <ProtectedRoute component={CandidatComponent} />
  },
  {
    path: "/profil",
    element: <ProtectedRoute component={ProfileComponent} />
  },
  {
    path: "/",
    element: <ProtectedRoute component={Homepage} />
  },
  {
    path: "/not_found",
    element: <NotFoundComponent />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  // </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();