import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Layout/Navbar';
import { Button, Container, Flex, Heading } from '@chakra-ui/react';
import ExamHeaderComponent from '../Components/ExamHeaderComponent';
import ExamResultQuestionCard from '../Components/ExamResultQuestionCard';
import ExamResultHeaderComponent from '../Components/ExamResultHeaderComponent';
import { FaArrowLeft } from 'react-icons/fa';
import { API_URL } from "../keys";

const QuestionnaireResultsComponent = () => {
    const { questionnaire_id } = useParams();
    const [questionnaire, setQuestionnaire] = useState(null)
    const navigate = useNavigate()


    const fetchExamQuestionnaire = async (id) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_done_questionnaire/responses/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            console.log("GETTING DATA", data)
            setQuestionnaire(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    useEffect(() => {
        fetchExamQuestionnaire(questionnaire_id);
    }, [])

    useEffect(() => {
        // console.log("QQQ", questionnaire)
    }, [questionnaire])


    if (!questionnaire) return null


    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4} mb={2}>
                    <Button onClick={() => navigate("/candidat/" + questionnaire.candidateQuestionnaire.candidate_id)} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour au candidat</Button>
                </Flex>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={2} color={"gray.700"}>Résultats</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <ExamResultHeaderComponent {...questionnaire.candidateQuestionnaire} />
                {questionnaire.candidateQuestionnaire.questionnaire_id.questions.map((question, index) => {
                    return <ExamResultQuestionCard key={index} {...question} questionIndex={index} candidateAnswers={questionnaire.candidateQuestionnaire.answers[index]} questionnaireAnswers={questionnaire.responses.filter((el) => el.questionId == question._id)[0]} />
                })}
            </Container>
        </>
    )
}

export default QuestionnaireResultsComponent