import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaFileSignature } from 'react-icons/fa'

const EmptyRunningQuestionnaireCard = ({isCandidate}) => {
    return (
        <Flex as='b' boxShadow={"base"} p={4} color={"gray.600"} direction="column" alignItems="center" justifyContent={'center'}>
            <FaFileSignature size={80} color={"teal"} />
            <Text mt={4} textAlign="center">{isCandidate ? "Vous n'avez pas de questionnaires à faire !" : "Aucun questionnaire est en cours pour ce candidat"}</Text>
        </Flex>
    )
}

export default EmptyRunningQuestionnaireCard