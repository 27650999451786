import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom"
import Navbar from '../Layout/Navbar';
import { Container, Button, Flex } from '@chakra-ui/react';
import QuestionnaireHeader from '../Components/QuestionnaireHeader';
import QuestionnairesQuestion from '../Components/QuestionnairesQuestion';
import { FaArrowLeft } from "react-icons/fa6";
import { API_URL } from "../keys";

const QuestionnaireComponent = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [questionnaire, setQuestionnaire] = useState({})

    const fetchQuestionnaire = async (questionnaireId) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/questionnaires/getuserquestionnaire/${questionnaireId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setQuestionnaire(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    };

    useEffect(() => {
        fetchQuestionnaire(id)
    }, [])

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4} mb={2}>
                    <Button onClick={() => navigate("/questionnaires")} size="sm" leftIcon={<FaArrowLeft />} colorScheme="teal" variant="ghost">Retour aux questionnaires</Button>
                </Flex>
                <Flex direction="column" gap={4}>
                    <QuestionnaireHeader {...questionnaire.questionnaire} permissions={questionnaire.permissions} />
                    {questionnaire && questionnaire.questionnaire && questionnaire.questionnaire.questions &&
                        questionnaire.questionnaire.questions.map((question, index) => <QuestionnairesQuestion key={index} {...question} answers={questionnaire.questionsAnswers} />)}
                </Flex>
            </Container>
        </>
    )
}

export default QuestionnaireComponent