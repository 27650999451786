import { Button, Container, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import ExamHeaderComponent from '../Components/ExamHeaderComponent'
import ExamQuestionCard from '../Components/ExamQuestionCard'
import { IoSend } from "react-icons/io5";
import { API_URL } from "../keys";


const CandidatQuestionnaireExam = () => {
    const { questionnaire_id } = useParams();
    const [questionnaire, setQuestionnaire] = useState(null)
    const [responsesChecked, setResponsesChecked] = useState([])
    const navigate = useNavigate()


    const fetchExamQuestionnaire = async (id) => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/questionnaires/getexamquestionnaire/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            const data = await response.json();
            setQuestionnaire(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    const handleResponseChange = (questionIndex, responsesIndex, value) => {
        let copy = [...responsesChecked]
        if (value) {
            copy[questionIndex].push(responsesIndex)
        } else {
            copy[questionIndex] = copy[questionIndex].filter(el => el != responsesIndex)
        }

        setResponsesChecked(copy)
    }

    const handleSubmit = async () => {
        const { name, description, questions} = questionnaire;
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(`${API_URL}/candidate_done_questionnaire/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
                body: JSON.stringify({
                    questionnaire_id,
                    name,
                    description,
                    questions,
                    answers: responsesChecked
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch questionnaire');
            }

            navigate("/")

        } catch (error) {
            console.error('Error fetching questionnaire:', error.message);
        }
    }

    useEffect(() => {
        fetchExamQuestionnaire(questionnaire_id);
    }, [])

    useEffect(() => {
        console.log("LOS QUESTIONNEROS", questionnaire)
        if (questionnaire) {
            let emptyArray = []
            questionnaire.questions.map(() => emptyArray.push([]))

            setResponsesChecked(emptyArray)
        }
    }, [questionnaire])

    useEffect(() => {
        console.log("UPDATE IN RESPONES", responsesChecked)
    }, [responsesChecked])

    if (!questionnaire) return null

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <ExamHeaderComponent {...questionnaire} />
                {questionnaire.questions.map((question, index) => (
                    <ExamQuestionCard {...question} questionIndex={index} handleChange={handleResponseChange} responsesChecked={responsesChecked} />
                ))}

                <Flex mt={4} justifyContent={"flex-end"}>
                    <Button colorScheme='teal' rightIcon={<IoSend />} onClick={() => handleSubmit()}>Envoyer mes réponses</Button>
                </Flex>
            </Container>
        </>
    )
}

export default CandidatQuestionnaireExam