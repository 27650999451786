import React from 'react'
import { Tag, Box, Text, Flex, Code } from '@chakra-ui/react';

const QuestionnairesQuestion = ({ _id, description, type, responses, answers }) => {
    const GetLabelFromType = () => {
        if (type === "unique") {
            return "Choix unique"
        } else if (type === "multiple") {
            return "Choix multiple"
        } else {
            return "Aucun"
        }
    }
    return (
        <Box w='100%' boxShadow='base' p={8} color="gray.700">
            <Flex alignItems="baseline" gap={4}>
                <Text as='b' fontSize='2xl'>{description}</Text>
                {/* <Tag fontSize='xs' type='whiteAlpha'>{_id}</Tag> */}
                <Tag fontSize='xs' type='whiteAlpha' ml="auto">{GetLabelFromType()}</Tag>
            </Flex>
            {responses.map((res, index) => <Text key={index} fontSize='md'>{String.fromCharCode(65 + index)} - <Code colorScheme={answers[_id] && answers[_id].includes(index) ? "green" : "red"}>{res}</Code></Text>)}
        </Box>
    )
}

export default QuestionnairesQuestion