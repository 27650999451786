import React, { useEffect, useState } from 'react'
import Navbar from '../Layout/Navbar'
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Container, Flex, Grid, Heading, Input, Stack, StackDivider, Tag, Text, Tooltip, useToast } from '@chakra-ui/react'
import CopyToClipboard from '../Utils/CopyToClipboard'
import { IoLogOutOutline } from 'react-icons/io5'
import { MdOutlineLogout } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { API_URL } from "../keys";
import ProfileUpdateTeam from '../Components/ProfileUpdateTeam'
import { FaCloudArrowDown, FaTrash } from 'react-icons/fa6'
import { LuMoreVertical } from "react-icons/lu";

const ProfileComponent = () => {

    const [userInformations, setUserInformations] = useState({ email: null, firstname: null, lastname: null, roles: null, teams: [] })
    const toast = useToast()
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false)
    const [team, setTeam] = useState(null)
    const [isUpdateProfile, setIsUpdateProfile] = useState(false)

    const loadUserProfile = async () => {
        let accessToken = localStorage.getItem('access-token');
        try {
            const response = await fetch(
                API_URL + '/users/infos',
                {
                    method: 'GET',
                    headers: {
                        'x-access-token': accessToken
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            console.log("user infos", data)
            const { lastname, firstname, email, _id } = data;
            setUserInformations(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }

    }

    const saveUserProfileUpdate = async () => {
        let { lastname, firstname, email, _id } = userInformations;
        let accessToken = localStorage.getItem('access-token');
        try {
            const response = await fetch(
                API_URL + '/users/' + _id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-access-token': accessToken
                    },
                    body: JSON.stringify({ lastname, firstname, email })
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            } else {
                toast({
                    description: "Votre profil a bien été modifié !",
                    status: 'info',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
            }

            const data = await response.json();
            loadUserProfile();
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const switchEditionProfileMode = async () => {
        if (!isUpdateProfile) setIsUpdateProfile(true)
        else {
            await saveUserProfileUpdate();
            await loadUserProfile();
            setIsUpdateProfile(false)
        }
    }

    useEffect(() => {
        loadUserProfile();
    }, [])

    const getTagByRole = (role) => {
        if (role === "member") return "Membre"
        else if (role === "administrator") return "Administrateur"
        else if (role === "candidate") return "Candidat"
    }

    const closeDrawer = () => {
        setIsVisible(false)
        setTeam(null)
    }

    return (
        <>
            <Navbar />
            <Container maxW="full" p={8}>
                <Flex alignItems='center' gap={4}>
                    <div style={{ width: 50, height: 5, background: "#EDF2F7" }}></div>
                    <Heading mb={2} color={"gray.700"}>Mon profil</Heading>
                    <div style={{ flex: 1, height: 5, background: "#EDF2F7" }}></div>
                </Flex>
                <Flex direction="column" gap={4}>
                    <Flex gap={2}>
                        <Card flex={1}>
                            <CardHeader display={"flex"} justifyContent={"space-between"}>
                                <Heading size='md'>Informations personnelles</Heading>
                                <Flex gap={2}>
                                    {isUpdateProfile ? <Button size={"sm"} variant={"ghost"} colorScheme='teal' onClick={() => {
                                        loadUserProfile();
                                        setIsUpdateProfile(false);
                                    }}>
                                        annuler les modifications
                                    </Button>
                                        : null}
                                    <Button size={"sm"} colorScheme={isUpdateProfile ? 'teal' : 'gray'} onClick={() => {
                                        switchEditionProfileMode()
                                    }} rightIcon={<LuMoreVertical />}>
                                        {isUpdateProfile ? "Sauvegarder les modifications" : "Modifier mes informations"}
                                    </Button>
                                </Flex>
                            </CardHeader>
                            <CardBody>
                                <Stack divider={<StackDivider />} spacing='4'>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Votre identifiant
                                        </Heading>
                                        <Text pt='2' fontSize='sm'>
                                            <Tooltip label='Cliquer pour copier' placement="right">
                                                <Tag
                                                    variant='solid'
                                                    colorScheme='teal'
                                                    cursor="pointer"
                                                    onClick={async () => {
                                                        let status = await CopyToClipboard(userInformations._id)
                                                        if (status) {
                                                            toast({
                                                                title: 'Presse papier.',
                                                                description: `Votre identifiant a bien été copié.`,
                                                                status: 'info',
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: 'top'
                                                            })
                                                        } else {
                                                            toast({
                                                                title: 'Presse papier.',
                                                                description: `Votre identifiant n'a pas pu être copié.`,
                                                                status: 'error',
                                                                duration: 9000,
                                                                isClosable: true,
                                                                position: 'top'
                                                            })
                                                        }
                                                    }}
                                                >
                                                    {userInformations._id}
                                                </Tag>
                                            </Tooltip>
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Nom
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={2} value={userInformations.lastname} onChange={e => setUserInformations({ ...userInformations, lastname: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.lastname}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Prénom
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={2} value={userInformations.firstname} onChange={e => setUserInformations({ ...userInformations, firstname: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.firstname}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Email
                                        </Heading>
                                        {isUpdateProfile ? <Input mt={2} value={userInformations.email} onChange={e => setUserInformations({ ...userInformations, email: e.target.value })} /> : <Text pt='2' fontSize='sm'>
                                            {userInformations.email}
                                        </Text>}
                                    </Box>
                                    <Box>
                                        <Heading size='xs' textTransform='uppercase'>
                                            Rôle
                                        </Heading>
                                        <Text pt='2' fontSize='sm'>
                                            {getTagByRole(userInformations.roles)}
                                        </Text>
                                    </Box>
                                </Stack>
                            </CardBody>
                        </Card>
                        <Card flex={1}>
                            <CardHeader>
                                <Heading size='md'>Vos actions</Heading>
                            </CardHeader>
                            <CardBody display={"flex"} flexDirection={"column"} gap={4}>
                                <Button colorScheme='red' leftIcon={<MdOutlineLogout />} onClick={() => {
                                    localStorage.removeItem("access-token")
                                    navigate("/login")
                                }}>Se déconnecter</Button>
                                <Button colorScheme='red' variant={"outline"} leftIcon={<MdOutlineLogout />} onClick={() => {
                                    // TODO DELETE ACCOUNT
                                }}>Supprimer mon compte</Button>
                                <Button variant={"outline"} leftIcon={<FaCloudArrowDown />} onClick={() => {
                                    // TODO DELETE ACCOUNT
                                }}>Récupérer les informations liés à mon compte</Button>
                            </CardBody>
                        </Card>
                    </Flex>
                    <Grid templateColumns='repeat(3, 1fr)' gap={2}>
                        {userInformations && userInformations.roles !== "candidate" && userInformations.teams.map((teamInfo, index) => (
                            <Card flex={1} key={index}>
                                <CardHeader display={"flex"} justifyContent={"space-between"}>
                                    <Heading size='md' textTransform={"uppercase"}>Équipe {index + 1}</Heading>
                                    <Button size={"sm"} colorScheme='teal' onClick={() => {
                                        setIsVisible(true)
                                        setTeam({ id: teamInfo._id, role: teamInfo.role })
                                    }} rightIcon={<LuMoreVertical />}>
                                        Voir le détail de l'équipe
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <Stack divider={<StackDivider />} spacing='4'>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Identifiant d'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                <Tooltip label='Cliquer pour copier' placement="right">
                                                    <Tag
                                                        variant='solid'
                                                        cursor="pointer"
                                                        onClick={async () => {
                                                            let status = await CopyToClipboard(teamInfo._id)
                                                            if (status) {
                                                                toast({
                                                                    title: 'Presse papier.',
                                                                    description: `L'identifiant de votre équipe ${teamInfo.name} a bien été copié.`,
                                                                    status: 'info',
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                    position: 'top'
                                                                })
                                                            } else {
                                                                toast({
                                                                    title: 'Presse papier.',
                                                                    description: `L'identifiant de votre équipe ${teamInfo.name} n'a pas pu être copié.`,
                                                                    status: 'error',
                                                                    duration: 9000,
                                                                    isClosable: true,
                                                                    position: 'top'
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {teamInfo._id}
                                                    </Tag>
                                                </Tooltip>
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Nom de l'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                {teamInfo.name}
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Description
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                {teamInfo.description}
                                            </Text>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Votre rôle dans l'équipe
                                            </Heading>
                                            <Text pt='2' fontSize='sm'>
                                                <Tag bg={teamInfo.role === "administrator" ? 'gray.600' : 'blue.400'} color="white">{getTagByRole(teamInfo.role)}</Tag>
                                            </Text>
                                        </Box>
                                    </Stack>
                                </CardBody>
                            </Card>
                        ))}
                    </Grid>
                </Flex>
            </Container >
            <ProfileUpdateTeam isVisible={isVisible} onClose={closeDrawer} team={team} reload={loadUserProfile} />
        </>
    )
}

export default ProfileComponent