import React, { useContext, useEffect, useState } from 'react'
import Navbar from './Layout/Navbar'
import { UserContext } from "./Authentication/ProtectedRoute";
import { Button, Flex, Grid, Heading, IconButton, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { FaChevronRight, FaFileCirclePlus, FaListCheck, FaListUl, FaPerson } from "react-icons/fa6";
import { FiCheckSquare } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import HomepageEmptyWork from './Components/HomepageEmptyWork';
import HomepageEmptyTable from './Components/HomepageEmptyTable';

import { API_URL } from "./keys";

const Homepage = () => {
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const [teams, setTeams] = useState([])
    const [statistiques, setStatistiques] = useState(null)


    useEffect(() => {
        if (user && user.roles === "candidate") navigate("/exam")
    }, [user, teams])

    const loadUserCandidats = async () => {
        let accessToken = localStorage.getItem('access-token');

        try {
            const response = await fetch(API_URL + '/teams/getteamsbyuser', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            setTeams(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    const loadStatistiques = async () => {
        let accessToken = localStorage.getItem('access-token');
        try {
            const response = await fetch(API_URL + '/statistiques/', {
                method: 'GET',
                headers: {
                    'x-access-token': accessToken
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            setStatistiques(data)
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    }

    useEffect(() => {
        loadUserCandidats()
        loadStatistiques()
    }, [])

    if (!user) return null

    return (
        <>
            <Navbar />
            <Flex gap={2} p={4} pb={0} mb={2}>
                <Flex flex={1} p={4} direction={"column"} boxShadow={"sm"}>
                    <Heading color={"gray.700"} mb={4}>Bonjour {user.firstname}, </Heading>
                    <Text color={"gray.700"}>Vous êtes actuellement sur votre tableau de bord, vous pouvez gérer et suivre vos candidats simplement grâce à cette interface !</Text>
                </Flex>
                <Flex flex={3} gap={2} color={"gray.700"}>
                    <Flex direction={"column"} alignItems={"center"} boxShadow={"sm"} p={4} background={"gray.50"} flex={1}>
                        <Heading as="b" fontSize={80}>{statistiques && statistiques.runningQuestionnaire && statistiques.runningQuestionnaire.length}</Heading>
                        <Text>Questionnaire en cours</Text>
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} boxShadow={"sm"} p={4} background={"gray.50"} flex={1}>
                        <Heading as="b" fontSize={80}>{statistiques && statistiques.doneQuestionnaire && statistiques.doneQuestionnaire.length}</Heading>
                        <Text>Questionnaire terminés</Text>
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} boxShadow={"sm"} p={4} background={"gray.50"} flex={1}>
                        <Heading as="b" fontSize={80}>{teams && teams.length}</Heading>
                        <Text>Nombre d'équipe</Text>
                    </Flex>
                    <Flex direction={"column"} alignItems={"center"} boxShadow={"sm"} p={4} background={"gray.50"} flex={1}>
                        <Heading as="b" fontSize={80}>{teams.reduce((acc, team) => acc + team.candidate.length, 0)}</Heading>
                        <Text>Nombre de candidat</Text>
                    </Flex>
                </Flex>
            </Flex>
            <Flex gap={2} p={4} pt={0} pb={2} color={"gray.700"} flexWrap={"wrap"}>
                {/* <Flex p={4} gridArea={"1 / 4 / 2 / 6"} direction={"column"} gap={2}>
                    <Heading as="h5" size={"lg"} color={"gray.700"}>Actions rapides</Heading>
                    <Button colorScheme='teal' variant={"ghost"} rightIcon={<FaFileCirclePlus />} justifyContent={"flex-start"}>Ajouter un questionnaire</Button>
                    <Button colorScheme='teal' variant={"ghost"} rightIcon={<RiMailSendFill />} justifyContent={"flex-start"}>Inviter un candidat</Button>
                    <Button colorScheme='teal' variant={"ghost"} rightIcon={<TbSchoolBell />} justifyContent={"flex-start"}>Créer une offre d'emploi</Button>
                </Flex> */}
                <Flex flex={1} boxShadow={"base"} gridArea={"2 / 1 / 4 / 3"} direction={"column"} p={8}>
                    <Heading as="h5" size={"lg"} color={"gray.700"} mb={4}>Candidats sans questionnaires</Heading>
                    {statistiques && statistiques.inactive_candidate && statistiques.inactive_candidate.length === 0 ? (
                        <HomepageEmptyWork />
                    ) : null}

                    {statistiques && statistiques.inactive_candidate && statistiques.inactive_candidate.length > 0 ? (<><Text>Ces candidats attendent d'avoir un ou plusieurs questionnaires à faire. <br />Le processus de recrutement peut être long pour eux, ne les laissez pas attendre trop longtemps !</Text>
                        <TableContainer flex={1} mt={4}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>Nom</Th>
                                        <Th>Prénom</Th>
                                        <Th>Email</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {statistiques && statistiques.inactive_candidate && statistiques.inactive_candidate.map((candidate, index) => {
                                        return <Tr key={index}>
                                            <Td>{candidate.lastname}</Td>
                                            <Td>{candidate.firstname}</Td>
                                            <Td>{candidate.email}</Td>
                                            <Td><Button size='sm' colorScheme='teal' rightIcon={<FaChevronRight />} onClick={() => navigate("/candidat/" + candidate._id)}>Détail du candidat</Button></Td>
                                        </Tr>
                                    })}

                                </Tbody>
                            </Table>
                        </TableContainer></>) : null}
                </Flex>
                <Flex flex={1} boxShadow={"base"} gridArea={"2 / 3 / 4 / 6"} direction={"column"} p={8}>
                    <Heading as="h5" size={"lg"} color={"gray.700"} mb={4}>Questionnaires en cours</Heading>
                    {statistiques && statistiques.runningQuestionnaire && statistiques && statistiques.runningQuestionnaire.length === 0 ? <HomepageEmptyTable text={"Les questionnaires en cours de vos candidats apparaîtront ici"} /> : null}
                    {statistiques && statistiques.runningQuestionnaire && statistiques && statistiques.runningQuestionnaire.length > 0 ? (<Flex>
                        <TableContainer flex={1}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>Nom</Th>
                                        <Th>Prénom</Th>
                                        <Th>Titre du questionnaire</Th>
                                        <Th>Date d'assignation</Th>
                                        <Th>Action</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {statistiques && statistiques.runningQuestionnaire && statistiques.runningQuestionnaire.map((infos, index) => {
                                        return <Tr key={index}>
                                            <Td>{infos.candidate_id.lastname}</Td>
                                            <Td>{infos.candidate_id.firstname}</Td>
                                            <Td>{infos.questionnaire_id.name}</Td>
                                            <Td>{Date.now()}</Td>
                                            <Td>
                                                <Tooltip label='Voir le profil du candidat'>
                                                    <IconButton size={"sm"} icon={<FaPerson />} mr={1} colorScheme='teal' onClick={() => navigate("/candidat/" + infos.candidate_id._id)} />
                                                </Tooltip>
                                                <Tooltip label='Voir le questionnaire'>
                                                    <IconButton size={"sm"} icon={<FaListUl />} colorScheme='teal' onClick={() => navigate("/questionnaire/" + infos.questionnaire_id._id)} />
                                                </Tooltip>
                                            </Td>
                                        </Tr>

                                    })}
                                    {/* {teams && teams.map((team, index) => <Tr key={index}>
                                    <Td>{team.name}</Td>
                                    <Td>{(team.candidate.length)}</Td>
                                </Tr>)} */}

                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>) : null}

                    {/* <Flex>
                        <TableContainer flex={1}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>Nom de l'équipe</Th>
                                        <Th>Nombre de candidat</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {teams && teams.map((team, index) => <Tr key={index}>
                                        <Td>{team.name}</Td>
                                        <Td>{(team.candidate.length)}</Td>
                                    </Tr>)}

                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex> */}
                </Flex>

            </Flex >
            <Flex gap={2} p={4} pt={0} color={"gray.700"}>
                <Flex flex={1} boxShadow={"base"} direction={"column"} p={8}>
                    <Heading as="h5" size={"lg"} color={"gray.700"} mb={4}>Questionnaires terminés</Heading>
                    {statistiques && statistiques.doneQuestionnaire && statistiques.doneQuestionnaire.length === 0 ? <HomepageEmptyTable text={"Les questionnaires en terminés de vos candidats apparaîtront ici"} /> : null}
                    {statistiques && statistiques.doneQuestionnaire && statistiques.doneQuestionnaire.length > 0 ? (
                        <Flex>
                            <TableContainer flex={1}>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th>Nom</Th>
                                            <Th>Prénom</Th>
                                            <Th>Titre du questionnaire</Th>
                                            <Th>Date d'assignation</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {statistiques && statistiques.doneQuestionnaire && statistiques.doneQuestionnaire.map((infos, index) => {
                                            return <Tr key={index}>
                                                <Td>{infos.candidate_id.lastname}</Td>
                                                <Td>{infos.candidate_id.firstname}</Td>
                                                <Td>{infos.questionnaire_id.name}</Td>
                                                <Td>{Date.now()}</Td>
                                                <Td>
                                                    <Flex gap={1}>

                                                        <Tooltip label='Voir le profil du candidat'>
                                                            <IconButton size={"sm"} icon={<FaPerson />} colorScheme='teal' onClick={() => navigate("/candidat/" + infos.candidate_id._id)} />
                                                        </Tooltip>
                                                        <Tooltip label='Voir les résultats du candidat'>
                                                            <IconButton size={"sm"} icon={<FiCheckSquare />} colorScheme='teal' onClick={() => navigate("/exam/responses/" + infos._id)} />
                                                        </Tooltip>
                                                        <Tooltip label='Voir le questionnaire'>
                                                            <IconButton size={"sm"} icon={<FaListUl />} colorScheme='teal' onClick={() => navigate("/questionnaire/" + infos.questionnaire_id._id)} />
                                                        </Tooltip>
                                                    </Flex>
                                                </Td>
                                            </Tr>

                                        })}
                                        {/* {teams && teams.map((team, index) => <Tr key={index}>
                                    <Td>{team.name}</Td>
                                    <Td>{(team.candidate.length)}</Td>
                                </Tr>)} */}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Flex>) : null}
                </Flex>
            </Flex>
        </>
    )
}

export default Homepage