const CopyToClipboard = (string_to_copy) => {

    return navigator.clipboard.writeText(string_to_copy)
        .then(() => {
            return true
        })
        .catch((error) => {
            return false
        });
}

export default CopyToClipboard